.map-component {
    z-index: 999999000000000000000000000 !important;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.width-170 {
    width: 170px;
}

.width-120 {
    width: 120px;
}

.min-width-120 {
    width: 120px;
}

.gap-10 {
    gap: 10px;
}

.gap-5 {
    gap: 5px;
}

.shipment-line-filter .p-multiselect {
    border: none !important;
}

.shipment-line-filter .p-multiselect .p-multiselect-label {
    position: relative;
    bottom: 4px;
}

.shipment-line-filter-en .p-multiselect .p-multiselect-label {
    left: 20px;
}

.shipment-line-filter .p-multiselect .p-multiselect-trigger {
    height: 30px !important;
}

.hover-display-parent:hover .hover-display {
    visibility: visible;
}

.hover-display {
    visibility: hidden;
}

.cursor-prevent:hover {
    cursor: not-allowed;
}

.top-chart-filters .dashboard-store-filter .p-multiselect {
    margin-right: 30px;
}

.top-chart-filters .dashboard-store-filter .p-multiselect .p-multiselect-label-container {
    margin-right: 10px;
    width: 246px;
    height: 35px;
}

body .p-datepicker .p-monthpicker .p-monthpicker-month.p-state-active {
    background-color: #FCA429 !important;
}

.income-month-filter .p-datepicker .p-datepicker-header {
    display: none !important;
}

.income-month-filter .p-datepicker td > a.p-state-active {
    background-color: #FCA429 !important;
}

.chart-bar-rounded .p-datepicker-buttonbar {
    display: none !important;
}

.dashboard-year-picker .p-monthpicker {
    display: none !important;
}

.dashboard-year-picker .p-datepicker-monthpicker select.p-datepicker-year {
    font-size: 17px;
    padding: 5px;
    border-radius: 5px;
    /*background-color: #FCA429;*/
}

/*.dashboard-year-picker .p-datepicker-next, .dashboard-year-picker .p-datepicker-prev {*/
/*    !*position: absolute!important;*!*/
/*    !*top: 5px!important;*!*/
/*}*/
.dashboard-year-picker .p-datepicker-next {
    position: absolute !important;
    top: 6px !important;
    right: -14px !important;
}

.dashboard-year-picker .p-datepicker-prev {
    position: absolute !important;
    top: 6px !important;
    left: -14px !important;
}

.manage-shipment-status-filter .p-dropdown-items-wrapper {
    /*width: 150px!important;*/
}

.progress-spinner {
    width: 100%
}

.progress-spinner .p-progress-spinner {
    width: 100%;
    text-align: center;
}
.date-picker-filter {
    cursor: pointer;
    border-radius: 4px;
    background-color: #FFFFFF;
    height: 100%;
    max-height: 35px;
    padding: 5px 10px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.date-picker-filter > input {
    font-size: 14px;
    border: 0;
    cursor: pointer;
}
.download-awb .button-group button.main-btn,
.p-confirm-dialog.p-dialog .p-dialog-footer button.p-confirm-dialog-accept,
.daterangepicker .applyBtn {
    height: 28px;
    width: fit-content;
    border-radius: 4px;
    color: #fff!important;
    font-size: 12px;
    border: unset;
    background: linear-gradient(90deg, #FCA429 0%, #F24645 100%);
    box-shadow: 0 4px 10px 0 rgba(16, 156, 241, 0.24);
    outline: 0;
    cursor: pointer;
}
body .p-confirm-dialog .p-dialog-content {
    padding: 1em 2em;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    .p-button-icon-left{
        font-size: 1.25em;
    }
}

.daterangepicker .cancelBtn {
    background: transparent;
    font-size: 12px;
}

.daterangepicker .calendar th {
    width: 35px !important;
    font-size: 11px !important;
}

.daterangepicker td {
    height: 35px !important;
}

.daterangepicker td.in-range {
    background-color: rgba(252, 164, 41, 0.29) !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    border-radius: 50% !important;
    background-color: #FCA429 !important;
}

.daterangepicker td.available:hover {
    border-radius: 50% !important;
}


.daterangepicker.dropdown-menu {
    overflow: hidden !important;
}

.customization-button .ngb-popover-window {
    top: 133px !important;
    left: 62px !important;
    max-width: 500px !important;
    width: 268px !important;
    padding-bottom: 15px;
}


.chart-bar-rounded rect {
    rx: 23px;
    ry: 10px;
}


.progress-spinner .p-progress-spinner-svg {
    margin-top: 5em;
}

.row {
    margin: 0px !important;
    padding: 0px !important;
}

.manage-shipment-date-filter .date-picker .p-inputtext {
    height: 32px !important;
}

.main-filters .toggle-toolbar .p-inputswitch-slider,
    /*.main-filters .manage-shipment-customize-switch .p-inputswitch-slider{*/
    /*    background-color: #FCA429!important;*/
    /*}*/
    /*:host ::ng-deep .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,*/
    /*:host ::ng-deep .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider,*/
    /*:host ::ng-deep .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {*/
    /*    background-color: #FCA429!important;*/
    /*}*/
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #FCA429 !important;
}

.disabled-toggle-switch .p-inputswitch-slider {
    background-color: #FFE3BC !important;
}

.main-filters .toggle-toolbar-disabled .p-inputswitch-slider,
.main-filters .manage-shipment-disabled-customize-switch .p-inputswitch-slider {
    background-color: #FFE3BC !important;

}

.date-picker .p-inputtext {
    border-color: var(--admin-secondary-action-color);
    background-color: transparent;
    width: 94%;
}

.manage-shipment-view .date-picker .p-inputtext {
    padding-right: 0;
    padding-left: 0;
}

.shipments-categories, .shipments-categories .p-selectbutton {
    width: 100%;
}

.shipments-categories .p-button {
    background: transparent !important;
    width: 25%;
    padding: 10px 0px;
}

.shipments-categories .p-button.p-state-active {
    background-color: var(--admin-table-note-background) !important;
    border: 1px solid var(--admin-light-text-color) !important;
    color: var(--admin-secondary-action-color) !important;
}

.v-space-separator {
    height: 3em;
    width: 100%;
}

.profile-popover .p-overlaypanel-content {
    padding: 0px !important;
}

.shipments-steps .p-steps-item {
    padding: 0px 20px !important;
}

.add-shipment-modal .modal-dialog {
    max-width: 75%;
}
.driver-check-in-list .modal-dialog,
.receive-packing-list .modal-dialog,
.scan-unlisted-packages .modal-dialog,
.optimized-routes .modal-dialog,
.receive-shipping-plan .modal-dialog,
.manage-warehouse .modal-dialog {
    max-width: 90%;
}

.direction-ltr {
    direction: ltr;
}

.shipment-paginator.p-paginator {
    direction: ltr;
    width: fit-content !important;
    margin: auto !important;
}

body.page-rtl .shipment-paginator.p-paginator{
    direction: rtl;
}

.p-paginator {
    background-color: var(--admin-paginator-background) !important;
}

.fa-spinner {
    color: #F77038 !important;
}

.loader i {
    color: #F77038;
}

.currency {
    font-style: normal;
    font-size: 14px;
    font-family: Roboto;
    line-height: 16px;
    letter-spacing: 0.13px;
    color: #4E4E4E;
}

.dashboard-multiselect-filter .dropdown-down,
.dashboard-multiselect-filter .dropdown-up {
    display: none !important;
}

.dashboard-multiselect-filter .dropdown-btn span:first-child {
    position: relative;
    left: 162px;
    font-size: 16px;
    color: #4E4E4E;
    font-family: 'almarai light';
    line-height: 18px;
    letter-spacing: .11px;
}

.dashboard-multiselect-filter-en .dropdown-btn span:first-child {
    position: unset;
}

.dashboard-multiselect-filter .dropdown-btn {
    background-color: #F4F4F4;
    border: none !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-state-active {
    background-color: var(--admin-primary-color) !important;
    color: var(--admin-paginator-selected-color) !important;
}

/* Spinner */
.spinner-container {
    width: 100%;
    text-align: center;
    margin-top: 10%;
}

/* Date Picker Style */

.from-container, .to-container {
    position: relative;
}

.manage-shipment-view .from-container .date-picker .p-inputtext {
    background-color: white;
}

.manage-shipment-view .to-container .date-picker .p-inputtext {
    background-color: white;
}

.to-container {
    margin-inline-start: 5px;
}

.from-container {
    margin-inline-end: 5px;
}

.date-picker-icon {
    position: absolute;
    top: 8px;
    right: 10px;
    color: var(--admin-light-text-color);
    z-index: 10;
}

.packages-table .p-paginator {
    background-color: white !important;
    border: none !important;
    padding: 0 !important;
}

/* Filter dates dropdown icon
   to be changed up on the body direction.
*/
body[style*="direction: rtl"] .date-picker-icon {
    left: 10px;
    right: unset;
}

body[style*="direction: rtl"] .email-details-container {
    -webkit-animation: slideInLeft 1s;
    animation: slideInLeft 1s;
}

body[style*="direction: rtl"] .user-item-container .remove-button {
    right: unset;
    left: 10px;
}

/*scrollbar*/
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #C1C1C1;
}

/* Avatar */
.user-container {
    display: flex;
    align-items: center;
}

.user-container * {
    padding: 0px 3px;
}

.avatar {
    width: 60px;
    border-radius: 50%;
}

/* Select List */
.select-input {
    -webkit-margin-start: 6px;
    -moz-margin-start: 6px;
    border: none;
    border-bottom: 1px solid var(--admin-light-text-color);
    border-radius: 0px;
}

/* invalid field-input */

.ng-invalid.ng-dirty, .ng-invalid.ng-touched {
    border-color: var(--admin-danger-color) !important;
}

.text-align-start {
    text-align: start;
}

.text-align-end {
    text-align: end;
}

.inbox-dropdown .p-overlaypanel-content {
    padding: 0px !important;
}

.action-button {
    padding: 5px 10px;
    border-radius: 6px;
    color: var(--admin-button-text-color);
}

html, body, app-root, app-home {
    height: 100%;
}

.full-height {
    height: 100%;
}

.dashboard-customization-switch .p-inputswitch-slider,
.continue-receive-switch .p-inputswitch-slider {
    background-color: #FCA429 !important;
}

.disabled-dashboard-customization-switch .p-inputswitch-slider,
.disabled-continue-receive-switch .p-inputswitch-slider {
    background-color: #FFE3BC !important;
}

.disabled-btn {
    opacity: 60%;
    cursor: no-drop;
    pointer-events: none;
}
.actions-container {
    background-color: #FFFAF3;
    border-top: 2px solid rgba(252,164,41,0.7);
    border-bottom: 2px solid rgba(252,164,41,0.7);
    gap:10px;
    width:  100%;
    padding: 0 27px
}
.action-container {
    position: relative;
    padding-top: 8px;
    padding-bottom: 9px;
}
.action-container .action {
    padding: 4px 40px!important;
}
.min-width-1700 {
    min-width: 1700px;
}

.packages-table {
    font-size: .8em !important;
}

.receive-from-driver-table table {
    display: block;
}

.packages-table table {
    width: 95%;
    margin: auto;
}

.packages-table th {
    padding: 15px;
    background-color: var(--admin-card-background);
    border: 1px solid var(--admin-light-text-color);
}

.packages-table td {
    padding: 5px 10px;
    overflow-wrap: break-word;
    border: 1px solid var(--admin-light-text-color);
}

.packages-table table tr:nth-child(odd) td {
    //background-color: var(--admin-light-text-color);
}

.packages-table input[type=checkbox] {
    width: 18px;
    height: 18px;
}

body[style*="direction: rtl"] .packages-table th {
    text-align: right;
}


input[disabled], select[disabled], textarea[disabled] {
    opacity: .6;
}

/* Submitting Spinner */
#submitting-container {
    width: 100%;
    margin: 0px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--admin-loading-container-background);
    z-index: 1000;
    top: 0px;
    left: 0px;
}

.shipments-table {
    font-size: 1.1em;
}

.modal-track-package-modal {
    width: 843px !important;
}

.modal-tracking-package-modal {
    height: 966px !important;
}

.modal-open .track-package-modal-class {
    overflow-y: hidden !important;
    height: 966px !important;
}

.modal-xl {
    max-width: 1643px !important;
}

.receive-driver-and-read-by-barcode .modal-content {
    top: 30px !important;
}

.receive-driver-and-read-by-barcode-en .modal-content {
    top: 30px !important;
}

.container-carried-packages, container-tracking-password {
    overflow-y: hidden !important;
}

.container-tracking-password .modal-content {
    top: 30px !important;
    min-width: 440px !important;
}

.container-carried-packages .modal-content {
    top: 30px !important;
    min-width: 550px!important;
}

ngb-modal-backdrop,  ngb-modal-window {
    z-index: 9999999!important;
}
ngb-popover-window {
    z-index: 99999999!important;
}
@media(max-width: 767px) {
    container-tracking-password .modal-content, .container-carried-packages .modal-content {
        min-width: unset!important;
        width: 100% !important
    }
}

.container-carried-packages-en .modal-content, .container-tracking-password-en .modal-content, .sub-customers-for-customer-component-en .modal-content {
    top: 30px !important;
}

.receive-shipping-plan .modal-content,
.manage-warehouse .modal-content {
    top: 30px !important;
}

.create-new-container .modal-content {
    width: 810px !important;
}
.shipments-validation-results .modal-content {
    width: 28vw!important;
}
.add-shelf-container .modal-dialog, .new-hub-container .modal-dialog{
    max-width: 100%!important;
    width: 779px;
    /*right: 36%;*/
}

.new-hub-container .modal-content,
.add-shelf-container .modal-content {
    width: 100%;
    box-shadow: 0 0 3px 3px rgba(161, 161, 161, 0.5);
}
.new-payment .modal-content {
    border-radius: 2rem;
    box-shadow: 0 0 16px 7px rgba(0, 0, 0, 0.25);
}
.notification-popover .p-overlaypanel-content {
    padding: 0px !important;
    width: 400px !important;
    height: 400px !important;
}
.export-packages-to .modal-content {
    width: 582px;
    margin: 23vh auto;
    box-shadow: 0 0 3px 3px rgba(161, 161, 161, 0.5);
}
@media(max-width: 767px) {
    .export-packages-to .modal-content {
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 1440px) {
    .export-packages-to .modal-content {
        margin: 15vh auto;
    }
}
.notification-popover .ui-overlaypanel-content {
    padding: 0px !important;
    width: 400px !important;
    height: 400px !important;
}

.notification-popover .p-overlaypanel {
    margin-top: 17px !important;
}
.create-new-user, .lcl-items-modal {
    z-index: 99999999!important;
}

.create-new-user{
    z-index: 9999999999999!important;
}
.supplier-items-modal {
    z-index: 6000!important;
}

.supplier-items-modal-backdrop {
    z-index: 5000!important;
}
.create-new-user .modal-dialog {
    min-width: 810px;
    height: 95%;
}
.asn-fees-details .modal-dialog {
    min-width: 1000px !important;
}

.create-new-user .modal-dialog .modal-content {
    box-shadow: 0 0 3px 3px rgba(161, 161, 161, 0.5);
}

.track-package-modal-class .modal-dialog {
    min-width: 1278px;
    margin-right: 33% !important;
}

.reset-password-modal .modal-dialog {
    width: 638px;
    max-width: 100%;
}

.new-hub-container .modal-dialog {
    /*min-width: 760px;*/
}

.attachment-container .modal-dialog {
    max-height: 600px;
    min-width: 635px;
    max-width: 700px;
}

.excel-import-modal .modal-dialog {
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.profile-popover .p-overlaypanel-content {
    padding: 0px !important;
}
.main-table {
    .p-datatable-wrapper {
        border-top: 2px solid var(--admin-primary-color);
    }
}
.data-table .p-datatable .p-datatable-thead > tr > th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 2;
}

.data-table table tr th,
.data-table table tr td {
    background-color: #FFFFFF !important;
    border: none !important;
}

.data-table table tr td {
    cursor: pointer;
}

.data-p-table {
    height: calc(100vh - 175px) !important;
    background-color: #FFFFFF !important;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even):not(.invalid-row, .valid-row) {
    background-color: #ffffff;
}


.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-tbody > tr > th {
    /*border-left: none!important;*/
    /*border-right: none!important;*/
    /*border-top: none!important;*/

}
.p-datatable {
    .p-datatable-tbody {
         > tr {
             > td, > th {
                 text-align: center !important;
             }
             > td {
                 border-bottom: 1px solid #EBEFF2 !important;
             }
         }
    }
}

.p-paginator {
    background-color: #ffffff !important;
    border: none !important;
}

.reset-button {
    background-color: #ffffff !important;;
}

:host ::ng-deep .p-datatable .p-datatable-thead > tr > th {
    background-color: #ffffff;
    /*border-left: none;*/
    /*border-right: none;*/
    /*border-top: none;*/
    text-align: center;
}



.loader-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    place-items: center;
}

.loader-spinner i {
    font-size: 40px;
}

.page-content {
    background-color: #F5F6F8;
}

.date-range-filter {
    width: 200px !important;
}

.date-range-filter input {
    font-family: Roboto;
    font-size: 12px !important;
    line-height: 14px;
    letter-spacing: .11px;
    color: #676767 !important;
    width: 80%;
    cursor: pointer;

}

.date-range-filter img {
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.date-range-filter i {
    position: relative;
    left: 5px;
    top: 9px;
    cursor: pointer;
}

.page-ltr .date-range-filter i {
    left: unset;
    right: 4px;
}

.header-filter {
    font-size: 12px;
    font-weight: bold;
    color: #676767;
}

.header-filter input {
    font-family: 'almarai';
    font-size: 12px;
    font-weight: bold;
    color: #4E4E4E;
}
.page-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 18px;
        line-height: 18px;
        color: #4e4e4e;
    }
    .total-number-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F7685B;
        color: #fff;
        font-size: 14px;
        font-family: var(--number-font);
        border-radius: 10px;
        padding-inline: 14px;
    }
}
.date-filter {
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        width: 14px;
        height: 14px;
    }
    input {
        border: none;
    }
}
.main-search-filter {
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-inline-start: 6px;
    height: 35px;
    input {
        border: none;
    }
}
.date-picker-data-search {
    height: 37px;
    padding: 5px 2px;
    display: flex;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.date-picker-data-search > span {
    padding: 5px 10px;
}

.date-picker-data-search > input {
    border: 0;
}

.p-tabview.p-tabview-top .p-tabview-nav li,
.p-tabview.p-tabview-top .p-tabview-nav li:not(.p-state-active):not(.p-state-disabled):hover {
    padding: 10px !important;
    font-size: 16px !important;
    font-family: "almarai light" !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    margin: 0 10px !important;
    border: none !important;
    background-color: #ffdead !important;
    color: #4E4E4E !important;
}

.p-tabview.p-tabview-top .p-tabview-nav li.p-state-active,
.p-tabview.p-tabview-top .p-tabview-nav li.p-state-active:hover {
    background-color: #ff7f50 !important;
    color: #ffffff !important;
    border: none !important;
}
.gradient-action {
    background: linear-gradient(
            90deg, #FCA429 0%, #F24645 100%);
    box-shadow: 0 4px 10px 0 rgba(16, 156, 241 , .24);
    border-radius: 4px;
    cursor: pointer!important;
    color: #FFFFFF;
    font-family: "almarai bold";
    padding: 4px 28px;
    max-height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.action-container .action {
    padding: 4px 40px
}
.gradient-action .sub-label{
    font-size: 12px;
    padding: 1px 0;
    font-family:  "almarai medium";
}
.actions-splitter {
    width: 1px;
    background-color: #FEE0B6;
    margin: 0 41px
}
.secondary-action-btn {
    background-color: #FEE0B6;
    border-radius: 4px;
    cursor: pointer!important;
    padding: 4px 28px;
    max-height: 42px;
    color: #676767 !important;
    border: none !important;
    font-family: "almarai medium"!important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.secondary-action-btn .sub-label {
    font-size: 12px;
    font-family:  "almarai medium";
    color: #676767;
}
.toggle-show-actions {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 4px 13px;
}
.toggle-show-actions .show-actions-switch {
    position: relative;
    top: .3rem;
}
.toggle-show-actions {
    place-items: center;
}
.toggle-show-actions .label {
    font-size: 12px;
}
.show-actions-switch .p-widget {
    font-size: 12px!important;
}
.search-icon-2 .fa-search {
    background-color: unset;
    color: #676767;
}
.search-filter {
    width: 12em;
    max-height: 30px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-inline-end: 15px;
}
.search-filter .search-icon-2 {
    height: 100%;
}
.search-filter .search-icon-2 i {
    font-size:12px;
    color: orange;
}
.search-filter .search-input input {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 14px;
    border-radius: 5px;
}
.table-row-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.header-label span {
    font-size: 18px;
    font-family: var(--header-title-font);
    color: var(--header-label-title-color);
    line-height: 18px;
}
.ticket-category-form .modal-content {
    width: 637px!important;
}
.flex-2d-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-confirmation-message, .custom-confirmation-message-backdrop {
    z-index: 999999999999999999!important;
}
.custom-confirmation-message .modal-content, .general-note-form .modal-content {
    width: fit-content!important;
    box-shadow: 0 0 3px 3px rgba(161,161,161,0.5);
    top: 235px!important;
    z-index: 99999999!important;
}
.ticketing-gradient-action {
    background: var(--ticketing-system-gradient-button)!important;
}

@media (max-width: 1440px) {
    .track-package-modal-class .modal-dialog {
        min-width: 893px;
        margin-right: 34% !important;
    }

    .receive-driver-and-read-by-barcode .modal-content {
        top: 15px !important;
    }

    .receive-driver-and-read-by-barcode-en .modal-content {
        top: 15px !important;
    }

    .container-carried-packages .modal-content, .container-tracking-password .modal-content {
        top: 15px !important;
    }

    .container-carried-packages-en .modal-content, .container-tracking-password-en .modal-content {
        top: 15px !important;
    }

    .receive-shipping-plan .modal-content,
    .manage-warehouse .modal-content {
        top: 15px !important;
    }

    .modal-open .track-package-modal-class {
        height: 655px !important;
    }

    .data-p-table {
        height: calc(100vh - 200px) !important;
    }

    .new-hub-container .modal-content,
    .add-shelf-container .modal-content {
        top: unset !important;
    }
}

@media (min-width: 768px)
and (max-width: 1605px) {
    .general-note-form .modal-content {
        top: 130px!important;
    }
}

@media only screen and (min-width: 768px) {
    .p-confirm-dialog {
        min-width: 425px;
    }

    .daterangepicker.dropdown-menu {
        max-height: 70vh;
        overflow: scroll;
    }
    .add-shelf-container .modal-dialog {
        right: unset!important;
    }
}

.new-shelf-container .modal-dialog {
    max-width: 80%;
}

.add-shelf-container .modal-dialog {
    height: 200px
}

body[style*="direction: rtl"] .dropdown-content {
    left: 0;
    right: auto;
}

body[style*="direction: rtl"] .barcode-label {
    margin-right: 12px;
    margin-left: 0;
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover, .btn:focus {
    text-decoration: none;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.zones-list {
    width: 100%;
}

body[style*="direction: rtl"] .title {
    float: right;
}

body[style*="direction: rtl"] .checkbox-option {
    /*float: right;*/
}

body[style*="direction: rtl"] .checkbox-label {
    float: right;
}

.p-checkbox .p-checkbox-box.p-state-active {
    background-color: #FCA429 !important;
    border-color: #FCA429 !important;
}

.modal-header {
    padding: 10px;
    background-color: var(--admin-dialog-header-background);
    color: var(--admin-dialog-header-text-color);
    font-size: .9em;
}

.modal-add-button, .modal-reset-button {
    display: inline-block;
    color: var(--admin-button-text-color);
    background-color: var(--admin-success-color);
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0px 5px;
    min-width: 140px;
}

.flex-modal > div {
    height: calc(100% - 56px);

}

.flex-modal .modal-content, .flex-modal .modal-content app-new-component-component, .flex-modal .modal-content app-manage-shelf {
    height: 100%;
}

.previous {
    background-color: var(--admin-btn-previous-background);
}

.next,.prev, .submit, .action-btn {
    background: linear-gradient(90deg, #FCA429 0%, #F24645 100%);
    color: #ffffff;
    /*background-color: var(--admin-btn-next-background);*/
    border: 0;
}

.close-modal img {
    width: 16px;
    height: 16px;
    cursor: pointer;
    float: left;
}

.page-ltr .close-modal img {
    float: right;
}

.next:disabled {
    background-color: var(--admin-disabled-button-background);
    cursor: no-drop;
}


.tabs-container > [class*=-tab] {
    padding: 10px 25px;
    border-radius: 5px 5px 0px 0px;
    margin: 0px 15px;
    background-color: var(--admin-tab-background);
    color: var(--admin-tab-color);
    border: 1px solid var(--admin-tab-color);
    border-bottom: none;
}

.tabs-container > [class*=-tab].selectedTab {
    background-color: var(--admin-active-tab-background);
    color: var(--admin-button-text-color);
    box-shadow: -1px -1px 7px var(--admin-active-tab-background);
}

.top-actions {
    border-bottom: 1px solid var(--admin-light-text-color);
    padding-top: 15px;
}

.tabs-container {
    display: flex;
    align-items: flex-end;
    margin: 0px 25px;
}

.upload-image {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    overflow: visible;
    z-index: 0;
    box-shadow: 3px 2px 15px 0px #c7c7c7;
    display: inline-block;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

app-upload-image-component {
    width: 100%;
    height: 100%;
}

.button-group > .button-small {
    width: 20px;
    padding: 0;
    margin: 0 0.25rem;
    font-size: 12px;
}

.button-group > .button-small-toggle {
    padding: 0;
    margin: 0 0.25rem;
    font-size: 12px;
}

.table-disabled-paginator .p-paginator {
    pointer-events: none;
}

.dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--admin-dialog-header-background);
    color: var(--admin-dialog-header-text-color);
}

.dialog-title {
    font-size: 1.2em;
    font-weight: 200;
}


.page-body {
    margin: 10px;
    background: white;
}

.submit-button {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    border: 0px;
    width: 100%;
    justify-content: center;
}

.show-password-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

body[style*="direction: rtl"] .show-password-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    right: unset;
}

.barcode-image {
    min-width: 120px;
    width: 100%;
    display: block;
    max-width: 200px;
    margin-bottom: 5px;
    cursor: pointer;
}

.barcode-label {
    margin-left: 12px;
    color: var(--admin-success-color);
}

.phone-section {
    display: flex;
    width: 95%;
}

.phone-section p {
    width: 100%;
    margin-bottom: 0;
}

.phone-image {
    width: 15%;
}

.phone-image {
    width: 15px;
}

body .p-datatable .p-datatable-tbody > tr > td {
    overflow-wrap: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
}

body[style*="direction: rtl"] .manage-zones-container .p-scrollpanel-bar-y {
    right: 0 !important;
}

body[style*="direction: rtl"] .shipping-lines-container .p-scrollpanel-bar-y {
    right: 0 !important;
}

.create-new-package {
}

.create-new-package .modal-dialog {
    max-width: 90% !important;
}

.p-paginator-pages a {
    width: unset !important;
    padding-left: 7px;
    padding-right: 7px;
}

.search-icon {
    border: none;
}

.search-input input {
    /*width: 20em;*/
    width: 35em;
    padding: 6px;
}
.search-input {
    margin: 0 10px;
}
.search-filter-container {
    display: flex;
    gap: 10px;
}
.hide-search-filter {
    background-color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 16px;
    border-radius: 4px;
}
.search-filter-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #a2a2a2;
    padding: 6px 8px;
    border-radius: 2px;
}
.search-filter-container .search-input input {
    border: none;
    padding: 3px;
}
.search-filter-container > div {
    background-color: #fff;
    border-radius: 4px;
}
.search-filter-toggle img {
    width: 18px;
    height: 18px;
}
.hide-search-filter span {
    font-size: 14px;
    color: #676767;
    font-family: "almarai medium";
}
.manage-shipment-status-filter .multiselect-dropdown .dropdown-list {
    width: 220px !important;
    /*height: 36px!important;*/
}

.manage-shipment-status-filter .multiselect-dropdown .dropdown-btn .dropdown-down,
.manage-shipment-status-filter .multiselect-dropdown .dropdown-btn .dropdown-up {
    display: none !important;
}

.manage-shipment-status-filter .multiselect-dropdown .dropdown-btn {
    text-align: center !important;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

input[pInputText]:focus {
    border: 1px solid #fca429 !important;
}

.search-container > input {
    min-width: 100px;
    padding-inline-start: 30px !important;
    border-radius: 20px !important;
}

.search-container > i.fa-search {
    color: #484747;
    position: absolute;
    left: 10px;
}

.search-container > i.fa-times {
    color: #484747;
    position: absolute;
    right: 10px;
}

.toggle-search-container > i.fa-search {
    color: #484747;
    position: relative;
    padding: 6px 14px;
    background-color: #FFFFFF;
    cursor: pointer !important;
}

body[style*="direction: rtl"] .search-container > i.fa-search {
    left: unset;
    right: 10px;
}

body[style*="direction: rtl"] .search-container > i.fa-times {
    right: unset;
    left: 10px;
}

.auto-complete-item button {
    background-color: #fff !important;
    border: 1px solid #a6a6a6 !important;
    padding-bottom: 1.5px;
    border-radius: 0 !important;
    height: 35px;
}

body[style*="direction: ltr"] .auto-complete-item button {
    border-left: none !important;
    padding-bottom: 1.6px;
}

body[style*="direction: rtl"] .auto-complete-item button {
    border-right: none !important;
    padding-bottom: 1.6px;
}

body[style*="direction: ltr"] .auto-complete-item input {
    border: 1px solid #a6a6a6 !important;
    margin: 0;
    border-right: none !important;
    border-radius: 0;
}

body[style*="direction: rtl"] .auto-complete-item input {
    border: 1px solid #a6a6a6 !important;
    margin: 0;
    border-left: none !important;
    border-radius: 0;
}

.auto-complete-item input,
.auto-complete-item button{
    min-height: 35px;
}

.p-inputgroup .p-autocomplete {
    height: 100%;
}

.auto-complete-item input {
    width: calc(100% - 2.357em);
}

.auto-complete-item button span {
    color: gray;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

.header-filters {
    display: flex;
    align-items: center;
    gap: 20px;
}

.cod-title {
    width: fit-content;
    font-size: 1.2em;
    padding: 10px 20px;
    color: #484747;
}

.total-number-badge {
    background-color: #F7685B;
    padding: 0 15px;
    border-radius: 10px;
    height: 20px;
    width: 45px;
    display: flex;
    place-content: center;
    place-items: center;

    span {
        font-size: 16px;
        line-height: 19px;
        font-family: Roboto;
        color: #FFFFFF;
    }
}

.auto-complete-container {
    width: 100% !important;
    /*margin: 0 0px 20px 0;*/
    /*padding: 0px;*/
    /*display: inline-flex;*/
    /*align-items: center;*/
}

.auto-complete-container input {
    border: none !important;
    width: calc(100% - 2.357em);
    margin: 0 7px;
    border-bottom: 2px solid rgba(31, 160, 249, 0.51) !important;
}

.auto-complete-container .p-inputtext input {
    border: none !important;
    width: 100%;
    margin: 0 7px;
    border-bottom: 2px solid rgba(31, 160, 249, 0.51);
}

.width95 {
    width: 95%;
}

.title {
    width: fit-content;
    font-size: 1.4em;
    font-weight: 200;
    padding: 10px;
    color: #484747;
    text-align: start;
}

body[style*="direction: ltr"] .flex-start-item {
    justify-self: flex-start;
    margin-right: auto;
}

body[style*="direction: rtl"] .flex-start-item {
    justify-self: flex-start;
    margin-left: auto;
}

body[style*="direction: rtl"] .p-autocomplete-panel .p-autocomplete-list-item {
    text-align: right;
}

.detailed-reports-container {
    display: flex;
    padding: 20px;
    flex-direction: column;
}

.detailed-report {
    overflow: auto;
    flex: 1;
}

//body[style*="direction: rtl"] .p-inputgroup .p-inputgroup-addon:last-child {
//    border-top-right-radius: 0 !important;
//    border-bottom-right-radius: 0 !important;
//    border-top-left-radius: 3px;
//    border-bottom-left-radius: 3px;
//}

body .p-confirm-dialog {
    z-index: 900000000000 !important;
}

body .p-dialog-mask {
    z-index: 900000000000 !important;
}

body .p-sidebar {
    overflow: auto;
}

body .p-sidebar.new-shipping-plan-sidebar {
    overflow: unset;
    //width: 1600px;
    width: fit-content;
    max-width: 100%;
    top: 150px;
    padding: 0;
}

.table-actions .p-splitbutton {
    display: flex;
}

.p-button-info {
    background-color: var(--admin-success-color);
    display: flex !important;
}

.table-actions .p-splitbutton.p-buttonset .p-button {
    background-color: var(--admin-success-color);
    font-size: 12px;
}

.header-filter-background input {
    background: white;
}

body[style*="direction: rtl"] .filters-header .p-float-label > label {
    right: 0.25em;
    left: unset;
}

.filters-header .p-float-label {
    /*width: 80%;*/
    /*display: inline-block;*/
}

body[style*="direction: rtl"] .p-menu .p-menuitem-link .p-menuitem-icon {
    margin-left: 0.5em;
}

.filters-header .p-float-label > label {
    font-size: 14px;
}

.filters-header > th {
    padding-bottom: 0 !important;
}

.filters-header input {
    font-weight: normal !important;
    color: #898989 !important;
}

.p-table-scrollable-header, .p-table-scrollable-footer {
    overflow: unset !important;
}

body[style*="direction: rtl"] .next .fas {
    left: 10px;
    right: auto !important;
    transform: rotate(180deg);
    top: 8px !important;
}

.travel-information .p-autocomplete {
    width: 100%;
}

.show-more-container {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.controller-button {
    padding: 5px 60px;
    position: relative;
    margin: 0px 10px;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    margin-bottom: 0 !important;
}

.controller-button .fas {
    position: absolute;
    top: 10px;
}

.show-more-button {
    margin: auto;
}

.receiver-more-option {
    position: absolute;
    left: 10px;
    top: 8px;
}

.zones-components-container .p-card {
    background-color: unset;
}

.line-break-anywhere {
    line-break: anywhere;
}

body .p-tabview .p-tabview-panels {
    background-color: unset !important;
}

body[style*="direction: rtl"] .p-tabview .p-tabview-nav li {
    float: right;
}

.margin-start-10 {
    margin-right: 10px;
}

body[style*="direction: rtl"] .margin-start-10 {
    margin-left: 10px;
    margin-right: unset;
}

.dropdown-list {
    overflow-y: hidden;
}

.manage-shipment-date-filter input {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: .22px;
    color: #4E4E4E;
    font-weight: bold;
    text-align: center !important;
}

.dropdown-list .item2 {
    font-size: 15px;
}

.expenses-style .p-calendar {
    width: 215px;
}

.runsheetCheckbox .p-checkbox-label {
    padding: 10px;
    font-size: 15PX;
}

.cards-loader {
    position: absolute;
    top: 50%;
    background-color: rgba(255, 165, 0, .6);
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.cards-loader i {
    font-size: 40px;
}

.group-tabs-container {
    display: flex;
}

.group-tabs-container .tab {
    /*border: 1px solid #A2A2A2;*/
    padding: 8px 5px;
    font-size: 12px;
    font-family: "almarai light";
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 5px;
    border: none;
    background-color: #ffdead;
    color: #4E4E4E;
}

.group-tabs-container .selectedTab {
    background-color: #ff7f50;
    color: #ffffff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-align: right!important;
}
.page-ltr .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-align: left !important;
}
:host ::ng-deep .p-datatable-tbody > tr:hover > td {
    background-color: #FFF0DC!important;
    box-shadow: 0 2px 8px 0 rgba(252,164,41,0.45)!important;
    cursor: pointer;
}
@media screen and (min-width: 992px) {
    .driverPopUp .modal-lg, .modal-xl {
        width: 90vw !important;
        max-width: fit-content !important;
    }
}

.admin-report-table .p-datatable-tbody {
    /*min-height: 400px;*/
    height: 186px;
}

.textAreaAds .select-input {
    max-height: 60px;
}

body .p-datepicker table td.p-datepicker-today a {
    /*background-color: orange!important;*/
    /*color: white!important;*/
}

.p-button.p-button-icon-only {
    padding: .213rem;
}

.cover-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contain-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.download-awb .button-group button.main-btn,
.p-confirm-dialog.p-dialog .p-dialog-footer button.p-confirm-dialog-accept,
.daterangepicker .applyBtn {
    height: 28px;
    width: fit-content;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    border: unset;
    background: linear-gradient(90deg, #FCA429 0%, #F24645 100%);
    box-shadow: 0 4px 10px 0 rgba(16, 156, 241, 0.24);
    outline: 0;
    cursor: pointer;
}


.download-awb .button-group button.secondary-button,
.p-confirm-dialog.p-dialog .p-dialog-footer button.p-confirm-dialog-reject {
    height: 28px;
    width: fit-content;
    vertical-align: middle;
    background-color: #fff;
    color: #FCA429;
    border: 1px solid #FCA429;
    border-radius: 4px;
    outline: 0;
    cursor: pointer;
}
.p-confirm-dialog.p-dialog .p-dialog-footer{
    padding: 0.55em 1em;
}

:host ::ng-deep .ng-dropdown-panel.ng-select-bottom {

}

ng-select > .ng-select-container {
    border: 1px solid #979797 !important;
}

ng-select > .ng-select-container > .ng-value-container {
    padding-left: 0 !important;
    padding-inline-start: 10px !important;
}

ng-select > .ng-select-container > .ng-value-container > .ng-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-inline-start: 10px !important;
    padding-inline-end: 50px !important;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
}

.invalid-message {
    color: red !important;
    font-size: 0.8em;
}

.lcl-items-modal .modal-dialog {
    max-width: calc(100vw - 200px);
}

body[style*="direction: rtl"] .geocoder-control-input.leaflet-bar {
    background-position: left !important;
    max-width: 220px;
}

app-file-uploader {
    width: 100%;
}

p-inplace > .p-inplace > .p-inplace-display {
    display: block;
    width: 100%;
}

.p-sidebar {
    z-index: 1001;
}
.p-sidebar-mask {
    z-index: 1000;
}

.loading-overlay {
    align-items: center;
    display: flex;
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.7;
    justify-content: center;
    z-index: 1;
    border-radius: 30px;
}
.total-records-number-badge {
    display: flex;
    padding-inline: 5px;
    border-radius: 10px;
    background-color: #f7685b;
    color: #fff;
    margin-inline: 5px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: var(--number-font);
}

.main-page-header {
    .actions-filters-container {
        .page-header-title {
            display: flex;
            justify-content: center;
            align-items: center;
            .title {
                font-size: 18px;
                line-height: 18px;
                color: #4e4e4e;
            }
            .total-number-badge {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #F7685B;
                color: #fff;
                font-size: 14px;
                font-family: var(--number-font);
                border-radius: 10px;
                padding-inline: 14px;
            }
        }

        display: flex;
        gap: 15px;
        .actions, .filters {
            display: flex;
            align-items: center;
        }
        .actions {

        }
        .filters {
            .filter {
                display: flex;
                gap: 5px;
                background-color: #fff;
                border-radius: 4px;
                padding-inline-start: 10px;
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        color : #676767;
                    }
                }
                input {
                    border: none;
                    padding-inline: 10px;
                    border-radius: 4px;
                    padding-block: 5px;
                }
            }

        }
    }
}
/* ********** Mobile Screen **********/
@media (max-width: 767px) {
    .ticket-category-form .modal-content {
        width: 100%!important;
        height: 100%!important
    }
    .custom-confirmation-message .modal-content, .general-note-form .modal-content {
        width: 100%!important;
    }

    .mobile-date-range-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:#fff;
        border-radius: 4px;
    }
    .mobile-date-range-filter {
        padding: 5px 10px;
    }
    .mobile-date-range-filter span img {
        width: 16px;
        height: 16px;
    }
    .hidden-sm-down {
        display: none !important;
    }

    .create-new-container .modal-content, shipments-validation-results .modal-content {
        width: 100%!important;
    }
    .create-new-user .modal-content {
        max-width:  46%!important;
    }
    .p-overlaypanel {
        width: 100%!important;
        left: unset!important;
    }


    .p-datatable-wrapper {
        padding-block: 15px!important;
    }

    .p-datatable  {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
            display: none !important;
        }

        .p-datatable-tbody > tr {
            display: table!important;
            width: 100% !important;
            border-bottom: 1px solid #676767!important;
            > td {
                border-bottom: none!important;
                display: table-row!important;
                &:first-child {
                }
            }
        }
    }

}

.input-value .p-inputtext {
    width: 100%;
    height: 54px;
}

body .p-dialog .p-dialog-header {
    border: 1px solid #c8c8c8;
    background-color: #f4f4f4;
    color: #333333;
    padding: 0.2em;
    font-weight: 400;
    border-bottom: 0 none;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    background-color: #FCA429 !important;
    border-color: #FCA429 !important;
}

body .p-button:not(.p-autocomplete-dropdown):enabled:hover {
    background: linear-gradient(90deg, #FCA429 0%, #F24645 100%);
    border: none;
    color: #FFFFFF;
}

body .p-selectbutton .p-button.p-highlight {
    background-color: var(--admin-primary-color);
    border-color: var(--admin-primary-color);
    color: #FFFFFF !important;
}

body {
    .p-inputgroup .p-inputgroup-addon {
        border: 1px solid #a6a6a6 !important;
        background-color: #eaeaea;
        color: #848484;
    }
    .date-picker .p-button:not(.p-autocomplete-dropdown):enabled:hover{
        background: #fef4e5!important;
    }
}

.update-destination-address{
}


ngb-popover-window {
    z-index: 99999999!important;
}
.input-label {
    z-index: 999!important;
}
.add-shelf-container, .add-shelf-container-backdrop {
    z-index: 999999999999999999!important;
}
.table-status-title {
    width: 51px;
    padding: 0px 14px;
    border-radius: 6.5px;
    background-color: #F2F2F2;
    color: #444444;
    text-align: center;
    position: relative;
    top: 6px;
    left: 7px;
    right: 0;
    margin: -8px auto 0;
    z-index: 2;

    &.active{
        background-color: #FCA429!important;
        color: #FFFFFF!important;
    }
}

.shared-close-icon {
    height: 35px;
    width: 35px;
    position: absolute;
    z-index: 2;
    left: -29px;
    top: -30px;
    text-align: center;
    padding-top: 5px;
    border-radius: 50%;
    cursor: pointer;
}
.page-ltr {
    .shared-close-icon {
        left: initial;
        right: -30px;
    }
}
.shared-close-icon img {
    height: 36px!important;
    width: 36px!important;
}

.notify-message-container-new-updates {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    transition: box-shadow ease 0.5s;
}
.notify-message-container-new-updates:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.notify-message-container-new-updates {
    background-color: #ffd28f;
    font-weight: bold;
    margin-top: 10px!important;
    border-radius: 8px;
}

.ar-message .p-message {
    border-width: 0 6px 0 0 !important;
}


.text-underline {
    text-decoration: underline;
}

.bin-items-dialog .modal-content {
    min-height: 700px!important;
}

.accounting-date-picker-icon {
    width: 14px;
    height: 14px;
}

.barcode-number .item-barcode-number {
    direction: ltr;
}

.header-actions {
    .action {
        padding-inline: 15px;
        background-color: #fff;
        border: unset;
        outline: 0;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        min-height: 35px;
        color: #4e4e4e;
        i {
            margin-inline-end: 10px;
        }
    }
}

.right-0{
 right: 0 !important;
}

.left-initial{
 left: initial !important;
}

.height-auto{
    height: auto !important;
}


.pi-sort-alt:before {
    content: '\e903\A\e902';
}

.pi-sort-amount-up-alt:before {
    content: '\e903';
}

.pi-sort-amount-down:before {
    content: '\e902';
}

.check-filter {
    display:  flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    margin-top: 10px;

    .label {
        background-color: #F2F2F2;
        display: inline-block;
        border-radius: 5px;
        font-size: 12px;
        font-weight: normal;
        position: absolute;
        top: -10px;
    }
    .values {
        display: flex;

        .item {
            height: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            cursor: pointer;
            border: 1px solid #979797;
            font-size: 12px;
            font-family: "almarai light";
        }
        .active-item {
            border-color: #FCA429;
            color: #FCA429;
        }
    }
}
