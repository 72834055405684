body {
    --header-title-font: "almarai medium";
    --ticket-title-font: "almarai medium";
    --ticket-description-font: "almarai medium";
    --almarai-medium: "almarai medium";
    --number-font : Roboto;
    --almarai-light: "almarai light";
    --input-label-font: "almarai light";
    --ticket-actions-font: "almarai medium";
    --ticket-input-font: "almarai medium";
    --ticket-header-table-font: "almarai medium";
    --ticket-table-string-font: "almarai medium";
    --ticket-info-label-font-family: "almarai medium";
    --category-input-label-font: "almarai light";
    --category-input-value-font: "almarai medium";
}
