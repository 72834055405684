/****

  RTL Styling Support

 */

body[dir='rtl'] {
  .p-calendar .p-datepicker .p-datepicker-header {
    .p-datepicker-prev, .p-datepicker-next {
      transform: rotateY(180deg);
    }
  }
}
