
@use "./app/shared/styles/shared-styles.scss";
@use "./app/shared/styles/colors.scss";
@use "./app/shared/styles/rtl.scss";
@use '../node_modules/primeicons/primeicons.css';
@use '../node_modules/primeng/resources/themes/saga-orange/theme.css';
@use '../node_modules/primeng/resources/primeng.min.css';
@use '../node_modules/animate.css/animate.min.css';
@use "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@use "../node_modules/intl-tel-input/build/css/intlTelInput.min.css";

/* You can add global styles to this file, and also import other style files */
@import "./app/shared/styles/shared-styles.scss";
@import "./app/shared/styles/colors.scss";
@import "./app/shared/styles/fonts.css";
@import '../node_modules/primeicons/primeicons.css';
//@import '../node_modules/primeng/resources/themes/nova-light/theme.scss';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeng/resources/themes/saga-orange/theme.css';
@import '../node_modules/animate.css/animate.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

/* add fonts */
/*@font-face {*/
/*    font-family: almarai;*/
/*    src: url(src/assets/fonts/Almarai-Regular.ttf);*/
/*}*/

/*@font-face {*/
/*    font-family: almarai;*/
/*    src: url(src/assets/fonts/Almarai-Bold.ttf);*/
/*    font-weight: bold;*/
/*}*/

@font-face {
    font-family: 'almarai medium';
    src: url(assets/fonts/almarai_medium.ttf);
    font-weight: 800;
}
@font-face {
    font-family: 'almarai bold';
    src: url(assets/fonts/almarai_bold.ttf);
    font-weight: bold;
}
@font-face {
    font-family: 'almarai light';
    src: url(assets/fonts/almarai_light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: almarai;
    src: url(assets/fonts/Almarai-Regular.ttf);
}
@font-face {
    font-family: 'roboto bold';
    src: url(assets/fonts/roboto_bold.ttf);
    font-weight: bold;
}
@font-face {
    font-family: 'roboto light';
    src: url(assets/fonts/roboto_light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: Roboto;
    src: url(assets/fonts/roboto_medium.ttf);
    font-weight: 800;
}
@font-face {
    font-family: Helvetica;
    src: url(assets/fonts/Helvetica_Regular.ttf);
    font-weight: 800;
}
@font-face {
    font-family: 'helvetica bold';
    src: url(assets/fonts/Helvetica_Bold.ttf);
    font-weight: bold;
}

/* TODO Main Gradient Animation */
/*!* ANIMATION *!*/
/*@keyframes main-gradient-fade {*/
/*    0%   {background:linear-gradient(90deg, #FCA429, #F24645);}*/
/*    2%  {background:linear-gradient(90deg, #FCA429, #F24844);}*/
/*    4%  {background:linear-gradient(90deg, #FCA429, #F24A44);}*/
/*    6%  {background:linear-gradient(90deg, #FCA429, #F24C43);}*/
/*    8% {background:linear-gradient(90deg, #FCA429, #F24E43);}*/
/*    10%  {background:linear-gradient(90deg, #FCA429, #F35042);}*/
/*    12%   {background:linear-gradient(90deg, #FCA429, #F35242);}*/
/*    14%  {background:linear-gradient(90deg, #FCA429, #F35441);}*/
/*    16%  {background:linear-gradient(90deg, #FCA429, #F35640);}*/
/*    18%  {background:linear-gradient(90deg, #FCA429, #F35840);}*/
/*    20% {background:linear-gradient(90deg, #FCA429, #F45A3F);}*/
/*    22%  {background:linear-gradient(90deg, #FCA429, #F45C3F);}*/
/*    24%   {background:linear-gradient(90deg, #FCA429, #F45E3E);}*/
/*    26%  {background:linear-gradient(90deg, #FCA429, #F4603E);}*/
/*    28%  {background:linear-gradient(90deg, #FCA429, #F4623D);}*/
/*    30%  {background:linear-gradient(90deg, #FCA429, #F5643C);}*/
/*    32% {background:linear-gradient(90deg, #FCA429, #F5663C);}*/
/*    34%  {background:linear-gradient(90deg, #FCA429, #F5683B);}*/
/*    36%   {background:linear-gradient(90deg, #FCA429, #F56A3B);}*/
/*    38%  {background:linear-gradient(90deg, #FCA429, #F56C3A);}*/
/*    40%  {background:linear-gradient(90deg, #FCA429, #F66E3A);}*/
/*    42%  {background:linear-gradient(90deg, #FCA429, #F67039);}*/
/*    44% {background:linear-gradient(90deg, #FCA429, #F67239);}*/
/*    46%  {background:linear-gradient(90deg, #FCA429, #F67438);}*/
/*    48%   {background:linear-gradient(90deg, #FCA429, #F67638);}*/
/*    50%  {background:linear-gradient(90deg, #FCA429, #F77837);}*/
/*    52%  {background:linear-gradient(90deg, #FCA429, #F77A36);}*/
/*    54%   {background:linear-gradient(90deg, #FCA429, #F77C36);}*/
/*    56%  {background:linear-gradient(90deg, #FCA429, #F77E35);}*/
/*    58%  {background:linear-gradient(90deg, #FCA429, #F78035);}*/
/*    60%  {background:linear-gradient(90deg, #FCA429, #F88234);}*/
/*    62% {background:linear-gradient(90deg, #FCA429, #F88434);}*/
/*    64%  {background:linear-gradient(90deg, #FCA429, #F88633);}*/
/*    66%   {background:linear-gradient(90deg, #FCA429, #F88833);}*/
/*    68%  {background:linear-gradient(90deg, #FCA429, #F88A32);}*/
/*    70%  {background:linear-gradient(90deg, #FCA429, #F98C32);}*/
/*    72%  {background:linear-gradient(90deg, #FCA429, #F98E31);}*/
/*    74% {background:linear-gradient(90deg, #FCA429, #F99031);}*/
/*    76%  {background:linear-gradient(90deg, #FCA429, #F99230);}*/
/*    78%   {background:linear-gradient(90deg, #FCA429, #F99430);}*/
/*    80%  {background:linear-gradient(90deg, #FCA429, #FA962F);}*/
/*    82%  {background:linear-gradient(90deg, #FCA429, #FA982F);}*/
/*    84%  {background:linear-gradient(90deg, #FCA429, #FA9A2E);}*/
/*    86% {background:linear-gradient(90deg, #FCA429, #FA9C2E);}*/
/*    88%  {background:linear-gradient(90deg, #FCA429, #FA9E2D);}*/
/*    90%   {background:linear-gradient(90deg, #FCA429, #FAA02D);}*/
/*    92%  {background:linear-gradient(90deg, #FCA429, #FAA12C);}*/
/*    94%  {background:linear-gradient(90deg, #FCA429, #FBA22C);}*/
/*    96%  {background:linear-gradient(90deg, #FCA429, #FBA32B);}*/
/*    98% {background:linear-gradient(90deg, #FCA429, #FCA42A);}*/
/*    100%  {background:linear-gradient(90deg, #FCA429, #FCA429);}*/
/*}*/

:root {
    --app-height: 100%;
}

* .page-rtl{
    font-family: almarai;
}
* .page-ltr{
    font-family: "roboto medium";
}
* {
    font-family: almarai;
}
body .p-component, body .p-element {
    font-family: "almarai", "Open Sans", "Helvetica Neue", "sans-serif" !important;
}
.daterangepicker .ranges {

    li:before {
        content: "\f111";
        font-family: "Font Awesome 5 Free";
        padding: 0 5px;
    }

    li.active:before {
        content: "\f058";
        font-family: "Font Awesome 5 Free";
    }
    li, li.active, li:hover {
        background: white;
        border: 0;
        color: #4E4E4E;
        //padding: 0;
    }
}

.page-rtl .daterangepicker .ranges li {
    text-align: right;
    direction: rtl;
}
*:focus {
    outline: none;
}

.words-first-char-capitalize {
    text-transform: capitalize;
}

.flex-1 {
    flex: 1;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.flex-table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .p-datatable-scrollable-wrapper {
        flex: 1;
        overflow: auto;
        border-bottom: 1px #c8c8c8 solid;
    }
    .p-datatable-wrapper {
        flex: 1;
        border-bottom: 1px #c8c8c8 solid;
    }
    .p-datatable-scrollable-view {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .p-datatable-scrollable-body {
        flex: 1;
        max-height: none !important;
        display: flex;
        flex-direction: column;
        .p-datatable-virtual-scroller {
            flex: 1;
            background: white;
            border: solid lightgrey;
            border-width: 0 1px;
        }
    }
}

.page-rtl {
    text-align: right;
    .flex-table .p-datatable-scrollable-header-box {
        margin-right: 0 !important;
    }
    .p-paginator .p-paginator-icon, .icon-to-rotated {
        transform: rotate(180deg);
    }
    .p-dropdown {
        .p-dropdown-trigger {
            right: auto !important;
            left: 1px;
        }
        .p-dropdown-label {
            padding-right: 0.429em !important;
        }
        .p-dropdown-clear-icon {
            right: unset;
            left: 2.357rem;
        }
    }
    .p-calendar {
        .p-datepicker-prev, .p-datepicker-next {
            transform: rotateY(180deg);
        }
    }
}

.single-report-page {
    padding: 20px;
}

button.icon {
    background: none;
    border: none;
    padding: 0;
}

button.icon[disabled] {
    cursor: no-drop;
}

button.icon:focus {
    outline: none;
}

.p-datatable .p-paginator-bottom .p-dropdown-label {
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-container {
    padding: 10px;
    background-color: var(--admin-dialog-header-background);
    color: var(--admin-card-background);
}

.flat-button,
.add-button,
.reset-button,
.pdf-button,
.excel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--admin-button-text-color);
    text-align: center;
    padding: 5px 10px;
    min-height: 40px;
    border-radius: 5px;
    margin: 0 5px;
    min-width: 140px;
    width: fit-content;
    cursor: pointer;
}


.btn-small{
    min-width: 80px;
    width: fit-content;
}

.add-button {
    color: white !important;
    /*background-color: var(--admin-success-color) !important;*/
    background: linear-gradient(90deg, #FCA429 0%, #F24645 100%);
    &:disabled {
        background-color: var(--admin-disabled-button-background);
        color: var(--admin-disabled-button-text-color);
    }
    box-shadow: 0 4px 10px 0 rgba(16,156,241,0.24);
}

.excel-button {
    background: linear-gradient(90deg, #41b06d 0%, #2d794b 100%);
    box-shadow: 0 4px 10px 0 rgba(16,156,241,0.24);
}

.pdf-button {
    background: linear-gradient(90deg, #EB1000 0%, #910a00 100%);
    box-shadow: 0 4px 10px 0 rgba(16,156,241,0.24);
}

/*.add-button:hover {*/
/*    background: #fca429;*/
/*    animation-name: main-gradient-fade;*/
/*    animation-duration: 0.2s;*/
/*}*/

.reset-button {
    color: #4e4e4e!important;
    /*color: white !important;*/
    /*background-color: var(--admin-success-color) !important;*/
}

.flat-button {
    background-color: white;
    color: #676767;
    border-radius: 4px;
}

.flat-button .label {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .13px;
    font-family: "almarai medium";
}

.add-button:disabled {
    background-color: var(--admin-disabled-button-background);
    color: var(--admin-disabled-button-text-color);
}

.disable {
    background-color: var(--admin-disabled-button-background) !important;
    /*color: var(--admin-disabled-button-text-color) !important;*/
}


.disabled-button {
    /*color: #ffffff!important;*/
    opacity: 70%;

    /*color: var(--admin-disabled-button-text-color) !important;*/
    /*background-color: var(--admin-disabled-button-background) !important;*/
    cursor: no-drop;
    pointer-events: none
}

.failure-list-container .p-scrollpanel-content {
    padding: 0 18px 18px 18px;
}

.history-card .p-card {
    margin-bottom: 20px;
}

:host ::ng-deep .google-visualization-tooltip {
    background: red;
}

/* **************  Custom three dots Spinner ************** */
.main-spinner-container {
    width: 100%;
    height: 100%;
    padding-top: 30vh;
}
.spinner-stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
}
.dot-flashing {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FCA429;
    color: #FCA429;
    animation: dotFlashing .7s infinite linear alternate;
    animation-delay: .3s;
}

.dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -30px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FCA429;
    color: #FCA429;
    animation: dotFlashing .7s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 30px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #FCA429;
    color: #FCA429;
    animation: dotFlashing .7s infinite alternate;
    animation-delay: .6s;
}

@keyframes dotFlashing {
    0% {
        background-color: #FCA429;
    }
    50%,
    100% {
        background-color: #ffddb7;
    }
}


@-webkit-keyframes fadeInFromDisplayNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromDisplayNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromDisplayNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromDisplayNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}
.ng-dropdown-panel.excel-dialog-ng-select {
    z-index: 999100000000 !important;
    position: fixed;
}
.ng-dropdown-panel{
    z-index: 999999999999 !important;
}
.p-toast .p-toast-icon {
    top: 0.6em;
    &.pi-times:before {
        content: '\e922';
    }
}

.p-sidebar-header {
    padding: 0 !important;
}
.p-sidebar-content {
    padding: 0 !important;
    position: unset !important;
    height: 100%;

}

.p-datatable-thead {
    display: table-row-group !important;
    tr {
        display: table-row !important;
        th {
            display: table-cell !important;
            border-left: unset !important;
            border-right: unset!important;
            border-top: 2px solid rgba(252, 164, 41, 0.7) !important;
            border-bottom: 1px solid #EBEFF2 !important;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: .11px;
            color: #444444;
            padding: 18px 20px;
            text-align: center !important;
        }
    }
}

.top-border-0 .p-datatable-thead {
   tr {
       th {
           border-top-width: 0 !important;
           background: transparent !important;
       }
   }
}

.p-datatable-tbody {
    display: table-row-group !important;
    tr {
        display: table-row !important;
        td {
            display: table-cell !important;
            border-top: unset !important;
            border-left: unset !important;
            border-right: unset !important;
            border-bottom: 1px solid #EBEFF2 !important;
            padding: 15px;
            text-align: center !important;
            font-size: 1em;
        }
    }
}

.p-datatable-tfoot {
    display: table-row-group !important;
    tr {
        display: table-row !important;
        td {
            display: table-cell !important;
            text-align: center !important;
            padding: 15px;
        }
    }
}

input.p-dropdown-filter {
    margin: 0 !important;
}
body {
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
        padding-right: 2.5rem;
    }
}
body .ui-widget.ui-togglebutton,
body .ui-widget.ui-togglebutton.ui-state-focus:not(.ui-state-active):not(.ui-state-disabled) {
    background-color: transparent;
}
body .ui-widget.ui-togglebutton.ui-state-active {
    background-color: var(--admin-primary-color);
    border-color: var(--admin-primary-color);
}
body .ui-widget.ui-togglebutton.ui-state-active:hover {
    background-color: var(--admin-primary-dark-color);
    border-color: var(--admin-primary-dark-color);
}

body .ui-widget.ui-selectbutton .ui-widget.ui-button,
body .ui-widget.ui-selectbutton .ui-widget.ui-button.ui-state-focus:not(.ui-state-active):not(.ui-state-disabled) {
    border-radius: 5px;
    background-color: transparent;
}

body .ui-widget.ui-selectbutton .ui-widget.ui-button.ui-state-active {
    background-color: var(--admin-primary-color);
    border-color: var(--admin-primary-color);
}

body .ui-widget.ui-selectbutton .ui-widget.ui-button.ui-state-active:hover {
    background-color: var(--admin-primary-dark-color);
    border-color: var(--admin-primary-dark-color);
}

i.fa, i.far, i.fas {
    font-family: "Font Awesome 5 Free" !important;
}

.p-dropdown:not(.p-disabled).p-focus, .p-inputtext:enabled:focus {
    outline: unset !important;
    outline-offset: unset !important;
    box-shadow: unset !important;
    border-color: unset !important;
}
.p-component{
    font-weight: unset;
}

body .p-button{
    color: #FFFFFF;
}

.p-datatable .p-datatable-thead > tr > th {
    label{
        font-weight: 300 !important;
    }
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon{
    color: #ffffff;
}

body .pi {
    font-size: 1.25rem;
}

.p-dropdown .p-dropdown-label{
    font-weight: bold;
}
body .p-datatable .p-datatable-tbody > tr > td{
    padding: 0.43em 0.86em;
}

.p-toast .p-toast-message .p-toast-message-content{
    padding: 0.6em !important;
}
//body .p-dialog .p-dialog-header {
//    border: 1px solid #c8c8c8;
//    background-color: #f4f4f4;
//    color: #333333;
//    padding: 1em;
//    font-weight: 400;
//    border-bottom: 0 none;
//}

html{
    font-size: 13px;
}

.z-index-1000000{
    z-index: 1000000000 !important;
}
.z-index-1000050{
    z-index: 1000000050 !important;
}

.container-carried-packages-backdrop,.container-carried-packages-en ,.shelve-cities-modal-backdrop {
    z-index: 99999999999 !important;
}
.container-carried-packages,.container-carried-packages-en, .shelve-cities-modal {
    z-index: 999999999999 !important;
}

.postpone-package-backdrop-index{
    z-index: 99999999999990 !important;
}
.postpone-package-index{
    z-index: 99999999999999 !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

.text-align-center{
    text-align: center;
}

.margin-top-minus-5px{
    margin-top: -5px;
}

body .z-index-9999999999999999999.p-dropdown-panel {
    z-index: 9999999999999999999 !important;
}

body .z-index-9999999999999999999{
    z-index: 9999999999999999999 !important;
}

.add-business-tab button {
    min-width: 80px;
    height: 30px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: #676767;
    border: 1px solid #A2A2A2;
    background-color: transparent;
    cursor: pointer;
    outline: 0;
}

.add-business-tab button.active {
    color: #FCA429;
    border-color: #FCA429;
}

.add-business-tab button:first-child img {
    width: 18px;
}

.add-business-tab button:last-child img {
    width: 22px;
}

.add-business-tab button:first-of-type.active {
    border-right-width: 1px;
}

.add-business-tab button:last-of-type.active {
    border-left-width: 1px;
}

.add-business-tab button:last-of-type.active img {
    width: 25px;
}

.page-ltr .add-business-tab button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right-width: 0;
}

.page-ltr .add-business-tab button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left-width: 0;
}

.page-rtl .add-business-tab button:first-child {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: 10px;
    border-left-width: 0;
    border-right-width: 1px;
}

.page-rtl .add-business-tab button:first-child {
    border-top-right-radius: 10px;
}

.page-rtl .add-business-tab button:last-child {
    border-top-right-radius: unset;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right-width: 0;
    border-left-width: 1px;
}

.page-rtl .add-business-tab button:last-child {
    border-bottom-right-radius: unset;
}

.page-rtl .add-business-tab button:first-of-type.active {
    border-left-width: 1px;
}

.page-rtl .add-business-tab button:last-of-type.active {
    border-right-width: 1px;
}

.enter-by-barcode {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #676767;
    letter-spacing: 0.3px;
}
