body {
    --admin-light-text-color: #a2a2a2;
    --admin-dashboard-text-color: #a2a2a2;
    --admin-line-separator-color: #e4e4e4;
    --admin-add-shipment-field-border: #e4e4e4;
    --admin-email-titles-color: rgba(0, 0, 0, 0.76);
    --admin-loading-container-background: rgba(0, 0, 0, 0.76);
    --admin-label-text-color: #333333;
    --admin-input-text-color: #333333;
    --admin-edit-icon-hover: #ff;
    --admin-input-select-background: #fff;
    --admin-table-note-background: #fff;
    --admin-dialog-header-text-color: #fff;
    /*--admin-dialog-header-background: #788397;*/
    --admin-dialog-header-background: #F77637;
    --admin-tab-background: #fff;
    --admin-active-tab-background: #788397;
    --admin-tab-color: #788397;
    --admin-search-logo-background: #788397;
    --admin-submit-button-background: #788397;
    --admin-button-text-color: #fff;
    --admin-disabled-button-text-color: #000;
    --admin-disabled-button-background: #a9a9a9;
    --admin-btn-previous-background: #585656;
    --admin-btn-next-background: #3d8cc2;
    --admin-menu-icon-color: #2491ec;
    --admin-404-color: #395f82;
    --admin-menu-itme-active-background: #395f82;
    --admin-menu-item-text-color: #788397;
    --admin-menu-hover-bacground: #e4e4e4;
    --admin-header-menu-text-color: #788397;
    --admin-sub-menu-active-background: #395f82b3;

    --admin-main-page-background: #f3f3f3;
    --admin-sub-menu-background: #f3f3f3;
    --admin-success-color: #00bd9f;
    --admin-danger-color: #e94648;
    --admin-secondary-action-color: #24c2e1;

    --admin-dashboard-bottom-icon-color: #b3afaf;
    --admin-card-background: #fff;
    --admin-card-spinner-background: #788397;
    --admin-paginator-background: #f4f4f4;
    --admin-paginator-selected-background: #007ad9;
    --admin-paginator-selected-color: #ffffff;
    --admin-side-menu-background: #fff;
    --admin-header-backgrond: #fff;
    --admin-page-background: #fff;

    /*--modal-background: #f6f6f6;*/
    --admin-replay-email-border-color: #f1f1f1;
    --admin-email-replay-input-border: #d2d2d2;
    --admin-email-replay-input-background: #f6f7f9;
    --admin-link-color: #007ad9;

    --admin-light-danger-color: #fed7d7;
    --admin-light-success-color: #d4edda;
    --admin-dark-success-color: #357e46;
    --admin-main-gradient: linear-gradient(90deg, #FCA429 0%, #F24645 100%);

    --admin-primary-color: #fca429;
    --admin-primary-dark-color: #dc9023;
    --admin-table-header-font-color: #444444;


/*    for new design*/
    --ticketing-popular-font-color: #4E4E4E;
    --default-label-font-color: #4E4E4E;
    --ticket-system-default-badge-background: #4E4E4E;
    --ticket-system-default-badge-font-color: #ffffff;
    --tikcet-attachment-item-background: #F7F7F7;
    --header-label-title-color: #4E4E4E;
    --tikcet-title-color: #4E4E4E;
    --tikcet-description-color: #4E4E4E;
    --dashbord-filter-item-font-color: #4E4E4E;
    --dashbord-section-tickets-count-font-color: #4E4E4E;
    --card-count-number-color: #3E4249;
    --dashbord-filter-item-background-color: #DADADA;
    --input-label-color: #4E4E4E;
    --input-value-color: #4E4E4E;
    --ticket-inputs-border-color: #979797;
    --header-title-background-color: #FFF4E5;
    --ticket-table-header-font-color: #444444;
    --ticket-table-td-value-font-color: #4E4E4E;
    --ticket-details-action-font-color: #4E4E4E;
    --ticket-info-label-font-color: #4E4E4E;
    --ticket-details-action-background-color: #F0F0F0;
    --category-input-label-color: #4E4E4E;
    --header-background-color: #FFF4E5;
    --ticket-reply-background-color: #F5F7F9;
    --ticket-reply-action-hover-background-color: #b9bbbd;
    --ticket-note-background-color: #FFF4E5;
    --ticket-note-action-hover-background-color:#EDE2D3;
    --ticketing-system-gradient-button :  linear-gradient(90deg, #676767 0%, #4E4E4E 100%);
    --ticketing-system-gradient-button-shadow: 0 4px 10px 0 rgba(16,156,241,0.24);
    --ticketing-system-gradient-button-font-color: #ffffff;
    --ticketing-spinner-color: #4E4E4E;
    --ticket-history-item-border-color: #676767;
    --ticket-history-item-date-time-color: #8F8F8F;
    --ticketing-system-tag-background-color: #F4F4F4;
    --ticket-view-history-action-background-color: #676767;
    --admin-tab-label-color: #676767;
    --admin-tab-background-color: #F5F5F5;
    --admin-active-tab-background-color: #FCA429;
    --admin-active-tab-label-color: #FFF;
    --admin-border-color: #979797;
    --main-top-border-color: #8F8F8F;
    --admin-warning-color: #FFCC00;
}
